.section {
  padding-top: 50px;
}

.section h1, .section h2 {
  margin-bottom: 20px;
}

.section p, .section li {
  line-height: 1.6;
  margin-bottom: 15px;
}

.section ul {
  margin-bottom: 20px;
  padding-left: 20px;
}