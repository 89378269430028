.footer {
  width: 100vw;
  padding: var(--space3XL) var(--spaceL);
 text-align: center;
}

.footer__link {
}

.footer__date {
  display: block;
  margin-top: 10px;
}

.footer__pdf-links {
  margin-top: 10px;
}

.footer__pdf-link {
}

.footer__pdf-link-separator {
  margin: 0 5px; /* Espacement entre le séparateur "|" et les liens PDF */
}